import { Navbar, Container, Button } from 'react-bootstrap'
import * as React from 'react'

const donationAddress = 'sm1qqqqqqxl2phuunmsp8dm7hpywszakex34ce52zgdu205q'
export default function TaterLowerNav() {
    const [isCopied, setIsCopied] = React.useState(false)

    const onCopyAddress = () => {
        navigator.clipboard.writeText(donationAddress)
        setIsCopied(true)
    }

    return (
        <Navbar fixed="bottom" expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Text>
                    Find Spacemesh Videos on: <a
                        target="_blank"
                        href="https://www.youtube.com/channel/UCakvG7QQp4oL0Rtpiei1yKg"
                    >
                        HakeHardware
                    </a>
                </Navbar.Text>
                <Navbar.Text>
                    <b>Keep this site going with tips:</b>{' '}
                    <Button onClick={onCopyAddress} size="sm">
                        {isCopied ? "Copied" : "Copy Address" }
                    </Button>
                </Navbar.Text>
            </Container>
        </Navbar>
    )
}
