import * as React from 'react'
import { Container, Card, Row, Col, Badge, Stack } from 'react-bootstrap'
import Pagination from 'react-bootstrap/Pagination'
import { getTransactions } from '../../api/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowLeft,
    faArrowRight,
    faCopy,
    faAngleDown
} from '@fortawesome/free-solid-svg-icons' // Import the desired icon

const maxVisiblePages = 5

function TransactionCard({ data }) {
    const [showBody, setShowBody] = React.useState(false)

    const { tx, accountInfo } = data

    let typeColor = 'green'
    if (tx.type === 'Sent') typeColor = 'grey'

    const onClickHeader = () => {
        setShowBody(!showBody)
    }

    const onCopyData = (data) => {
        navigator.clipboard.writeText(data)
    }

    return (
        <Card bg="light" className="transaction-card-margin">
            <Card.Header style={{ cursor: 'pointer' }}>
                <Container onClick={() => onClickHeader()}>
                    <Row>
                        <Col>
                            <Badge>{accountInfo.address.slice(-8)}</Badge>
                            {tx.type === 'Sent' ? (
                                <FontAwesomeIcon
                                    color="red"
                                    size="lg"
                                    icon={faArrowRight}
                                    style={{
                                        padding: '0px 10px 0px 10px',
                                    }}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    color="green"
                                    size="lg"
                                    icon={faArrowLeft}
                                    style={{
                                        padding: '0px 10px 0px 10px',
                                    }}
                                />
                            )}

                            <Badge>
                                {tx.principalAccount === accountInfo.address
                                    ? tx.receiverAccount.slice(-8)
                                    : tx.principalAccount.slice(-8)}
                            </Badge>
                            <Badge
                                style={{
                                    margin: '0px 10px 0px 10px',
                                }}
                                bg={tx.status === 0 ? 'success' : 'danger'}
                            >
                                {tx.status === 0 ? 'Successful' : 'Error'}
                            </Badge>
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col md={3}>
                            <span
                                style={{
                                    fontSize: '.8rem',
                                    paddingRight: '10px',
                                }}
                            >
                                {tx.dateFormatted}
                            </span>
                        </Col>
                        <Col md={3}>
                            <span
                                style={{
                                    color: typeColor,
                                    fontWeight: 'bold',
                                }}
                            >
                                {tx.amountFormatted}
                            </span>
                        </Col>
                        <Col md={6} style={{ textAlign: 'right' }}>
                        <FontAwesomeIcon
                                    size="lg"
                                    icon={faAngleDown}
                                />
                        </Col>
                    </Row>
                </Container>
            </Card.Header>
            <Card.Body className={showBody ? null : 'transaction-card-hide'}>
                <Container>
                    <Row>
                        <Col md={2}>
                            <b>Sender</b>
                        </Col>
                        <Col className="transaction-data">
                            {tx.principalAccount}
                            <FontAwesomeIcon
                                icon={faCopy}
                                style={{
                                    cursor: 'pointer',
                                    paddingLeft: '5px',
                                }}
                                onClick={() => onCopyData(tx.principalAccount)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <b>Receiver</b>
                        </Col>
                        <Col className="transaction-data">
                            {tx.receiverAccount}
                            <FontAwesomeIcon
                                icon={faCopy}
                                style={{
                                    cursor: 'pointer',
                                    paddingLeft: '5px',
                                }}
                                onClick={() => onCopyData(tx.receiverAccount)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <b>Amount</b>
                        </Col>
                        <Col className="transaction-data">
                            {tx.amountFormatted}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <b>Fee</b>
                        </Col>
                        <Col className="transaction-data">
                            {tx.feeFormatted}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <b>Epoch</b>
                        </Col>
                        <Col className="transaction-data">{tx.epoch}</Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <b>Layer</b>
                        </Col>
                        <Col className="transaction-data">{tx.layer}</Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <b>Method</b>
                        </Col>
                        <Col className="transaction-data">{tx.method}</Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <b>Counter</b>
                        </Col>
                        <Col className="transaction-data">{tx.counter}</Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    )
}

export default function Transactions({ accountInfo }) {
    const [transactions, setTransactions] = React.useState([])
    const [currentPage, setCurrentPage] = React.useState(1)
    const [totalPages, setTotalPages] = React.useState(0)
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState(null)

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await getTransactions(
                currentPage,
                accountInfo.address
            )
            setTotalPages(Math.ceil(response.total / 20))
            setTransactions(response.transactions)
        }
        fetchData()
    }, [currentPage, accountInfo])

    const handlePageChange = (page) => {
        setCurrentPage(page)
    }

    const handleFirstPage = () => {
        setCurrentPage(1)
    }

    const handleLastPage = () => {
        setCurrentPage(totalPages)
    }

    const renderPageNumbers = () => {
        const pageNumbers = []
        const startPage = Math.max(
            1,
            currentPage - Math.floor(maxVisiblePages / 2)
        )
        const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1)

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </Pagination.Item>
            )
        }

        return pageNumbers
    }

    const displayTransactions = () => {
        return transactions.map((tx) => {
            return <TransactionCard key={tx.id} data={{ tx, accountInfo }} />
        })
    }

    return (
        <Container>
            <Stack>{displayTransactions()}</Stack>

            <Pagination>
                <Pagination.First
                    onClick={handleFirstPage}
                    disabled={currentPage === 1}
                />
                <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                />
                {renderPageNumbers()}
                <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                />
                <Pagination.Last
                    onClick={handleLastPage}
                    disabled={currentPage === totalPages}
                />
            </Pagination>
        </Container>
    )
}
