import { useLoaderData } from 'react-router-dom'
import * as React from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Container } from 'react-bootstrap'
import NoAccount from './NoAccount'
import Overview from './Overview'
import Nodes from './Nodes'
import Rewards from './Rewards'
import Transactions from './Transactions'
import { getAccountInfo } from '../../api/api'

export async function loader(params) {
    return await getAccountInfo(params)
}

export default function Account() {
    const accountInfo = useLoaderData()

    return (
        <Container>
            {!accountInfo ? (
                <NoAccount />
            ) : (
                <Tabs
                    defaultActiveKey="overview"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="overview" title="Overview">
                        <Overview accountInfo={accountInfo} />
                    </Tab>
                    <Tab eventKey="nodes" title="Nodes">
                        <Nodes accountInfo={accountInfo} />
                    </Tab>
                    <Tab eventKey="rewards" title="Rewards">
                        <Rewards accountInfo={accountInfo} />
                    </Tab>
                    <Tab eventKey="transactions" title="Transactions">
                        <Transactions accountInfo={accountInfo} />
                    </Tab>
                </Tabs>
            )}
        </Container>
    )
}
