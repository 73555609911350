import * as React from 'react'
import { getAccountRewards } from '../../api/api'
import { Container, Card, Row, Col, Badge, Stack } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowLeft,
    faArrowRight,
    faCopy,
    faAngleDown,
} from '@fortawesome/free-solid-svg-icons' // Import the desired icon
import Pagination from 'react-bootstrap/Pagination'
import { v4 as uuidv4 } from 'uuid'

const maxVisiblePages = 5

function RewardCard({ reward }) {
    const onCopyData = (data) => {
        navigator.clipboard.writeText(data)
    }

    return (
        <Card bg="light" className="transaction-card-margin">
            <Card.Body>
                <Container>
                    <Row>
                        <Col>
                            {reward.smesherId.slice(-8)}{' '}
                            <FontAwesomeIcon
                                icon={faCopy}
                                style={{
                                    cursor: 'pointer',
                                    paddingLeft: '5px',
                                }}
                                onClick={() => onCopyData(reward.smesherId)}
                            />
                        </Col>
                        <Col>
                            <span style={{ color: 'green' }}>
                                +{reward.rewardsFormatted}
                            </span>
                        </Col>
                        <Col style={{ textAlign: 'right' }}>{reward.layer}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <span
                                style={{
                                    fontSize: '.8rem',
                                    paddingRight: '10px',
                                }}
                            >
                                {reward.dateFormatted}
                            </span>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    )
}

export default function Rewards({ accountInfo }) {
    const [rewards, setRewards] = React.useState([])
    const [currentPage, setCurrentPage] = React.useState(1)
    const [totalPages, setTotalPages] = React.useState(0)

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await getAccountRewards(
                currentPage,
                accountInfo.address
            )
            setTotalPages(Math.ceil(response.total / 20))
            setRewards(response.rewards)
        }
        fetchData()
    }, [currentPage, accountInfo])
    const handlePageChange = (page) => {
        setCurrentPage(page)
    }

    const handleFirstPage = () => {
        setCurrentPage(1)
    }

    const handleLastPage = () => {
        setCurrentPage(totalPages)
    }

    const renderPageNumbers = () => {
        const pageNumbers = []
        const startPage = Math.max(
            1,
            currentPage - Math.floor(maxVisiblePages / 2)
        )
        const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1)

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </Pagination.Item>
            )
        }

        return pageNumbers
    }

    const displayRewards = () => {
        return rewards.map((reward) => {
            return <RewardCard key={uuidv4()} reward={reward} />
        })
    }

    return (
        <Container>
            <Stack>{displayRewards()}</Stack>

            <Pagination>
                <Pagination.First
                    onClick={handleFirstPage}
                    disabled={currentPage === 1}
                />
                <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                />
                {renderPageNumbers()}
                <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                />
                <Pagination.Last
                    onClick={handleLastPage}
                    disabled={currentPage === totalPages}
                />
            </Pagination>
        </Container>
    )
}
