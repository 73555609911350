import * as React from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Accounts() {

    const [inputValue, setInputValue] = React.useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const handleSubmit = (e) => {
        e.preventDefault()
        navigate({ pathname: `${inputValue}` })
        setInputValue('')
    }

    const displayInfo = () => {
        return (
            <Row>
                <Col className="text-center">
                    <p>Enter an Account ID and Press Look Up</p>
                </Col>
            </Row>
        )
    }

    return (
        <Container>
            <Row className="search-input">
                <Col xs={{ span: 12 }} lg={{span: 6, offset: 3}}>
                    <Form onSubmit={handleSubmit}>
                        <InputGroup className="mb-3">
                            <Form.Control
                                required
                                placeholder="Account ID"
                                aria-label="Account ID"
                                aria-describedby="input-desc"
                                onChange={(e) => setInputValue(e.target.value)}
                                value={inputValue}
                            />
                            <Button
                                type="submit"
                                variant="spacemesh"
                                id="account-submit-btn"
                            >
                                Look Up
                            </Button>
                        </InputGroup>
                    </Form>
                </Col>
            </Row>

            {location.pathname === '/accounts' ? displayInfo() : null}
            <Outlet />
        </Container>
    )
}
