import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import { LinkContainer } from 'react-router-bootstrap'

import logo from '../images/potato.png'
import googlePlay from '../images/googleplay.png'
import ios from '../images/ios.png'

function TaterNav() {

    const startBounceAnimation = () => {
        for (let i = 0; i < 10; i++) {
            createPotato()
        }
    }

    const createPotato = () => {
        const potato = document.createElement('div')
        potato.className = 'potato'
        potato.style.left = `${Math.random() * window.innerWidth}px`
        potato.style.top = `${Math.random() * window.innerHeight}px`

        document.body.appendChild(potato)

        // Remove the potato after animation duration (2000ms in this case)
        setTimeout(() => {
            document.body.removeChild(potato)
        }, 2000)
    }

    return (
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand>
                    <LinkContainer to="/">
                        <Nav.Link>
                            <img
                            onClick={startBounceAnimation}
                                alt=""
                                src={logo}
                                width="30"
                                height="30"
                                className="d-inline-block align-top"
                            />{' '}
                            Tater Tracker
                        </Nav.Link>
                    </LinkContainer>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <LinkContainer to="/">
                            <Nav.Link>Network</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/accounts">
                            <Nav.Link>Accounts</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/rankings">
                            <Nav.Link>Rankings</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/poets">
                            <Nav.Link>PoETs</Nav.Link>
                        </LinkContainer>
                    </Nav>
                    <Navbar.Text>
                        <span style={{ fontSize: '.7rem' }}>
                            Spacemesh Reward Tracker App
                        </span>
                        <br />
                        <a
                            target="_blank"
                            href="https://play.google.com/store/apps/details?id=io.swarmbit.spacemesh_reward_tracker"
                        >
                            <img style={{ height: '20px' }} src={googlePlay} />
                        </a>{' '}
                        {' | '}
                        <a
                            target="_blank"
                            href="https://apps.apple.com/pt/app/spacemesh-reward-tracker/id6463492791"
                        >
                            <img style={{ height: '20px' }} src={ios} />
                        </a>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default TaterNav
