import Card from 'react-bootstrap/Card';

export default function NoAccount() {
    return(
        <Card border="danger">
        <Card.Header>ERROR</Card.Header>
        <Card.Body>
          <Card.Title>Account Not Found</Card.Title>
          <Card.Text>
            Looks like this account has not been seen by the network yet. Please verify you entered the correct address and that it has published an ATX or has been spawned.
          </Card.Text>
        </Card.Body>
      </Card>
        )
}