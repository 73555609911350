export function getEpochEndDate() {
    const startDate = new Date('2023-07-14T08:00:00Z')
    const currentDate = new Date()

    const timeDiff = currentDate.getTime() - startDate.getTime()
    const twoWeeksInMilliseconds = 14 * 24 * 60 * 60 * 1000
    const intervalsPassed = Math.floor(timeDiff / twoWeeksInMilliseconds)

    const nextMilestone = new Date(
        startDate.getTime() + (intervalsPassed + 1) * twoWeeksInMilliseconds
    )

    const lastMilestone = new Date(
        startDate.getTime() + intervalsPassed * twoWeeksInMilliseconds
    )

    if (nextMilestone > currentDate) {
        const timePassedSinceLastMilestone =
            timeDiff - intervalsPassed * twoWeeksInMilliseconds
        const percentagePassed =
            (timePassedSinceLastMilestone / twoWeeksInMilliseconds) * 100

        return {
            nextMilestone: nextMilestone.toLocaleString(),
            lastMilestone: lastMilestone.toLocaleString(),
            percentagePassed: percentagePassed.toFixed(),
        }
    } else {
        const timePassedSinceLastMilestone =
            timeDiff - (intervalsPassed + 1) * twoWeeksInMilliseconds
        const percentagePassed =
            (timePassedSinceLastMilestone / twoWeeksInMilliseconds) * 100

        return {
            nextMilestone: new Date(
                startDate.getTime() +
                    (intervalsPassed + 2) * twoWeeksInMilliseconds
            ).toLocaleString(),
            lastMilestone: lastMilestone.toLocaleString(),
            percentagePassed: percentagePassed.toFixed(),
        }
    }
}

export function calculateCycleGap(phaseShift, duration) {
    // The current date
    const currentDate = new Date()

    // Genesis Date
    const genesisDate = new Date('2023-07-14T08:00:00Z')

    // Get the Genesis Cycle Gap Start
    let nextGapStart = new Date(
        genesisDate.getTime() + (phaseShift - duration) * 60 * 60 * 1000
    )

    // Get the Genesis Cycle Gap Stop
    let nextGapEnd = new Date(
        genesisDate.getTime() + phaseShift * 60 * 60 * 1000
    )

    // Loop until the next cycle gap end date has not passed. We want to include in-progress Cycle Gaps
    while (nextGapEnd < currentDate) {
        nextGapStart = new Date(
            nextGapStart.getTime() + 2 * 7 * 24 * 60 * 60 * 1000
        )
        nextGapEnd = new Date(
            nextGapEnd.getTime() + 2 * 7 * 24 * 60 * 60 * 1000 - 100
        )
    }

    // The time of the current PoET Cycle Start
    let poetCycleStart = new Date(
        nextGapEnd.getTime() - 2 * 7 * 24 * 60 * 60 * 1000 + 1000
    )

    // The time of the current PoET Cycle End
    let poetCycleEnd = new Date(nextGapStart.getTime() - 100)

    // Calculate the progress into the PoET Cycle
    const cycleProgress =
        currentDate >= nextGapStart
            ? (100).toFixed(2)
            : (
                  ((2 * 7 * 24 * 60 * 60 * 1000 -
                      duration * 60 * 60 * 1000 -
                      (poetCycleStart -
                          currentDate -
                          (poetCycleStart - nextGapStart) -
                          duration * 60 * 60 * 1000)) /
                      (2 * 7 * 24 * 60 * 60 * 1000 -
                          duration * 60 * 60 * 1000)) *
                  100
              ).toFixed(2)

    // Calculate the cycle gap progress percentage
    const cycleGapProgress =
        currentDate >= nextGapStart && currentDate < nextGapEnd
            ? (
                  ((currentDate - nextGapStart) / (nextGapEnd - nextGapStart)) *
                  100
              ).toFixed(2)
            : (0).toFixed(2)

    // Determine the status based on the current date
    let cycleGapStatus
    if (currentDate >= nextGapStart && currentDate <= nextGapEnd) {
        cycleGapStatus = 'In Progress'
    } else {
        cycleGapStatus = 'Waiting'
    }

    let timeUntilCycleGapEnds = formatTimeDuration(nextGapEnd - currentDate)
    let timeUntilCycleEnds = currentDate > nextGapStart ? '00:00:00:00' : formatTimeDuration(nextGapStart - currentDate)

    // console.log('PoET Cycle Start', poetCycleStart.toLocaleString())
    // console.log('PoET Cycle End', poetCycleEnd.toLocaleString())

    // console.log('Next Gap Start', nextGapStart.toLocaleString())
    // console.log('Next Gap End', nextGapEnd.toLocaleString())

    // console.log('Percent of Cycle Passed', cycleProgress)
    // console.log('Percentage of Cycle Gap Passed', cycleGapProgress)
    // console.log('Cycle Gap Status', cycleGapStatus)

    // console.log('Time Until CG Ends', timeUntilCycleGapEnds)
    // console.log('Time Until Cycle Ends', timeUntilCycleEnds)
    // console.log('-------------------------')

    return {
        poetCycleStart: poetCycleStart.toLocaleString(),
        poetCycleEnd: poetCycleEnd.toLocaleString(),
        nextGapStart: nextGapStart.toLocaleString(),
        nextGapEnd: nextGapEnd.toLocaleString(),
        cycleProgress,
        cycleGapProgress,
        cycleGapStatus,
        timeUntilCycleEnds,
        timeUntilCycleGapEnds
    }
}

export function getPoets() {
    return [
        {
            poetName: 'Default',
            duration: 12,
            phaseShift: 240,
            website: 'https://spacemesh.io/',
            discordLink: 'https://discord.com/invite/yVhQ7rC',
            endpoints: [
                "https://mainnet-poet-0.spacemesh.network",
                "https://mainnet-poet-1.spacemesh.network",
                "https://mainnet-poet-2.spacemesh.network",
                "https://poet-110.spacemesh.network",
                "https://poet-111.spacemesh.network",
                "https://poet-112.spacemesh.network"
            ]
        },
        {
            poetName: 'Team24 Late Phase',
            duration: 24,
            phaseShift: 288,
            discordLink: 'https://discord.gg/HzyA2Z7EKW',
            website: 'https://team24.co',
            endpoints: [
                'https://poet-1.team24.co',
                'https://poet-2.team24.co',
                'https://poet-3.team24.co',
                'https://poet-4.team24.co'
            ]
        },
        {
            poetName: 'Team24 Early Phase',
            duration: 24,
            phaseShift: 120,
            discordLink: 'https://discord.gg/HzyA2Z7EKW',
            website: 'https://team24.co',
            endpoints: [
                'https://eos-1.team24.co',
                'https://eos-2.team24.co',
                'https://eos-3.team24.co'
            ]
        },
        // {
        //     poetName: 'Test',
        //     duration: 24,
        //     phaseShift: 127,
        //     servers: 1,
        //     fastestPoet: 1,
        //     discordLink: '#none'
        // },
    ]
}

function formatTimeDuration(duration) {
    const days = Math.floor(duration / (24 * 60 * 60 * 1000))
    const hours = Math.floor(
        (duration % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
    )
    const minutes = Math.floor((duration % (60 * 60 * 1000)) / (60 * 1000))
    const seconds = Math.floor((duration % (60 * 1000)) / 1000)

    // Add leading zeros
    const formattedDays = days.toString().padStart(2, '0')
    const formattedHours = hours.toString().padStart(2, '0')
    const formattedMinutes = minutes.toString().padStart(2, '0')
    const formattedSeconds = seconds.toString().padStart(2, '0')

    return `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}
