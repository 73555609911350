import * as React from 'react'
import {
    Table,
    Container,
    Card,
    Row,
    Col,
    ProgressBar,
    Button,
    Alert,
} from 'react-bootstrap'
import { useLoaderData } from 'react-router-dom'
import { getNetworkInfo } from '../api/api'
import { getEpochEndDate } from '../helpers'
import Form from 'react-bootstrap/Form'

export async function loader() {
    return await getNetworkInfo()
}

export default function Network() {
    const networkInfo = useLoaderData()
    const [percentagePassed, setPercentagePassed] = React.useState(0)
    const [epochEndDate, setEpochEndDate] = React.useState(0)
    const [epochStartDate, setEpochStartDate] = React.useState(0)
    const [value, setValue] = React.useState(4)
    const [total, setTotal] = React.useState(false)

    React.useEffect(() => {
        const updateProgressBar = () => {
            const epochDateInfo = getEpochEndDate()
            setPercentagePassed(epochDateInfo.percentagePassed)
            setEpochEndDate(epochDateInfo.nextMilestone)
            setEpochStartDate(epochDateInfo.lastMilestone)
        }

        updateProgressBar()
        const intervalId = setInterval(updateProgressBar, 300000)
        return () => clearInterval(intervalId)
    }, [])

    const displayNetworkInfo = () => {
        return (
            <Card bg="light" className="text-center">
                <Card.Body>
                    <Container>
                        <Row>
                            <Col className="info-card-base">
                                <Card.Title className="info-card-title">
                                    {networkInfo.epoch}
                                </Card.Title>
                                <Card.Text className="info-card-text">
                                    Epoch
                                </Card.Text>
                            </Col>
                            <Col className="info-card-base">
                                <Card.Title className="info-card-title">
                                    {networkInfo.layer}
                                </Card.Title>
                                <Card.Text className="info-card-text">
                                    Layer
                                </Card.Text>
                            </Col>
                            <Col className="info-card-base">
                                <Card.Title className="info-card-title">
                                    {networkInfo.networkSize}
                                </Card.Title>
                                <Card.Text className="info-card-text">
                                    Network Size
                                </Card.Text>
                            </Col>
                            <Col className="info-card-base">
                                <Card.Title className="info-card-title">
                                    {networkInfo.totalAccounts}
                                </Card.Title>
                                <Card.Text className="info-card-text">
                                    Total Accounts
                                </Card.Text>
                            </Col>
                            <Col className="info-card-base">
                                <Card.Title className="info-card-title">
                                    {networkInfo.totalActiveSmeshers}
                                </Card.Title>
                                <Card.Text className="info-card-text">
                                    Total Smeshers
                                </Card.Text>
                            </Col>
                            <Col className="info-card-base">
                                <Card.Title className="info-card-title">
                                    {networkInfo.circulatingSupplyFormatted}
                                </Card.Title>
                                <Card.Text className="info-card-text">
                                    Circulating Supply
                                </Card.Text>
                            </Col>
                            <Col className="info-card-base">
                                <Card.Title className="info-card-title">
                                    {networkInfo.epochSubsidyFormatted}
                                </Card.Title>
                                <Card.Text className="info-card-text">
                                    Current Epoch Subsidy
                                </Card.Text>
                            </Col>
                            <Col className="info-card-base">
                                <Card.Title className="info-card-title">
                                    {networkInfo.priceFormatted}
                                </Card.Title>
                                <Card.Text className="info-card-text">
                                    Price
                                </Card.Text>
                            </Col>
                            <Col className="info-card-base">
                                <Card.Title className="info-card-title">
                                    {networkInfo.marketCapFormatted}
                                </Card.Title>
                                <Card.Text className="info-card-text">
                                    Market Cap
                                </Card.Text>
                            </Col>
                            <Col className="info-card-base">
                                <Card.Title className="info-card-title">
                                    {networkInfo.earnings}
                                </Card.Title>
                                <Card.Text className="info-card-text">
                                    4 SU Earnings
                                </Card.Text>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
        )
    }

    const displayEpochTicker = () => {
        return (
            <>
                <Card bg="light" className="text-center">
                    <Card.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <h3>Epoch {networkInfo.epoch}</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>{epochStartDate}</Col>
                                <Col md={8}>
                                    <ProgressBar
                                        variant="spacemesh"
                                        now={percentagePassed}
                                        label={`${percentagePassed}%`}
                                    />
                                </Col>
                                <Col>{epochEndDate}</Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </>
        )
    }

    const calculateTotal = () => {
        return (value * networkInfo.earningsRaw).toFixed(2)
    }

    const handleRangeChange = (e) => {
        setValue(e.target.value)
    }

    const onCalculate = () => {
        setTotal(calculateTotal(value))
    }



    const displayEarningsCalculator = () => {
        return (
            <>
                <Card bg="light" className="text-center">
                    <Card.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <Form>
                                        <Form.Group>
                                            <Form.Label>{value} SU ({(value*64)/1024} TiB)</Form.Label>
                                            <Form.Range
                                                value={value}
                                                onChange={handleRangeChange}
                                                min={4}
                                                max={10000}
                                                step={1}
                                            />
                                            <Form.Control onChange={handleRangeChange} value={value} type="text" placeholder="Enter SU" />
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <hr />
                                    {total
                                        ? (
                                            <p>
                                                Your expected earnings are <b>{total} SMH</b> this Epoch
                                            </p>
                                        )
                                        : (<p>'Enter or Select an SU and click Calculate to see potential earnings this Epoch'</p>)}
                                </Col>
                                <Button onClick={onCalculate}>Calculate</Button>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </>
        )
    }

    return (
        <Container style={{ marginTop: '20px', marginBottom: '20px' }}>
            <Alert variant='warning'>There are still some issues so if something is broken I am working on it.</Alert>
            <div className="big-card-padding">{displayEpochTicker()}</div>
            <div className="big-card-padding">{displayNetworkInfo()}</div>
            <div className="big-card-padding">
                {displayEarningsCalculator()}
            </div>
        </Container>
    )
}
