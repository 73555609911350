import { getLargestWealth, geteffectiveNumUnitRanking } from '../../api/api'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons' // Import the desired icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

export default function LargestWealth() {
    const [accounts, setAccounts] = React.useState([])

    const getData = async () => {
        const data = await getLargestWealth()
        setAccounts(() => data)
    }

    React.useEffect(() => {
        getData()
    }, [])
    return (
        <Table>
            <thead>
                <tr>
                    <th>Rank</th>
                    <th>Account</th>
                    <th>Balance</th>
                    <th>Value</th>
                    <th>Total Rewards</th>
                    <th>Share</th>
                </tr>
            </thead>
            <tbody>
                {accounts.map((node, index) => {
                    const link = `/accounts/${node.address}`
                    return (
                        <tr key={node.address}>
                            <td>#{index + 1}</td>
                            <td>
                                {node.address.slice(-8)}{' '}
                                <Link to={link}>
                                    <FontAwesomeIcon
                                        icon={faArrowUpRightFromSquare}
                                    />
                                </Link>
                            </td>
                            <td>{node.balanceFormatted}</td>
                            <td>{node.accountValueFormatted}</td>
                            <td>{node.totalRewardsFormatted}</td>
                            <td>{node.share}</td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}