import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css'
import potato_dance from './images/potato_dance.gif'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import TaterError from './components/TaterError'
import Network, { loader as networkLoader } from './components/Network'
import Accounts from './components/Accounts'
import Account, { loader as accountLoader } from './components/accounts/Account'
import Rankings from './components/Rankings'
import Poets from './components/Poets'

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: <TaterError />,
        children: [
            {
                index: true,
                element: <Network />,
                loader: networkLoader,
            },
            {
                path: '/accounts',
                element: <Accounts />,
                children: [
                    {
                        path: ':accountId',
                        element: <Account />,
                        loader: ({ params }) => accountLoader(params),
                    },
                ],
            },
			{
				path: '/rankings',
				element: <Rankings />
			},
            {
                path: '/poets',
                element: <Poets />
            }
        ],
    },
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <>
        <RouterProvider router={router} />
    </>
)
