const API_URL = 'https://spacemesh-api-v2.swarmbit.io'
const HEADERS = {
    method: 'GET',
    headers: {
        'x-api-key': '90e19edf-bfe3-46ef-89f3-a68fade35632'
    }
}

export const getNetworkInfo = async () => {
    const networkInfo = await fetch(`${API_URL}/network/info`, HEADERS).then(
        (response) => {
            if (!response.ok) {
                return false
            }

            return response.json()
        }
    )

    if (networkInfo.price < 0) {
        networkInfo.price = 0
        networkInfo.marketCap = 0
    }

    const networkSize = `${(
        (networkInfo.effectiveUnitsCommited * 64) /
        1024 /
        1024
    ).toFixed(2)} PiB`
    const circulatingSupplyFormatted = `${(
        networkInfo.circulatingSupply / 1000000000
    ).toLocaleString()}`
    const epochSubsidyFormatted = `${(
        networkInfo.epochSubsidy / 1000000000
    ).toLocaleString()}`
    const marketCapFormatted = `${new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(networkInfo.marketCap / 1000000000)}`
    const priceFormatted = `${new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(networkInfo.price)}`

    const earnings = `${(
        ((4 / networkInfo.effectiveUnitsCommited) * networkInfo.epochSubsidy) /
        1000000000
    ).toFixed(2)} SMH`

    const earningsRaw = (
        ((1 / networkInfo.effectiveUnitsCommited) * networkInfo.epochSubsidy) /
        1000000000
    ).toFixed(2)

    return {
        ...networkInfo,
        networkSize,
        circulatingSupplyFormatted,
        epochSubsidyFormatted,
        marketCapFormatted,
        priceFormatted,
        earnings,
        earningsRaw
    }
}

export const getAccountInfo = async (params) => {
    const handleRewardDetails = (rewardDetails) => {
        const currentEpochRewardsSumFormatted = `${(
            rewardDetails.rewardsSum / 1000000000
        ).toLocaleString()} SMH`

        const accountSize = `${(
            (rewardDetails.eligibility.effectiveNumUnits * 64) /
            1024
        ).toFixed(2)} TiB`

        let sizePerNode = 0
        if (rewardDetails.eligibility.effectiveNumUnits > 0) {
            sizePerNode = `${(
                ((rewardDetails.eligibility.effectiveNumUnits / nodeInfo.total) *
                    64) /
                1024
            ).toFixed(2)} TiB`
        }

        let currentEpochRewardValue = 'Not Available'

        if (networkInfo.price > 0 && rewardDetails.rewardsCount > 0) {
            currentEpochRewardValue = `${new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(
                (rewardDetails.rewardsSum / 1000000000) *
                    networkInfo.price
            )}`
        }
        let averageRewardSize = 0
        if (rewardDetails.rewardsCount > 0) {
            averageRewardSize = `${(
                rewardDetails.rewardsSum /
                rewardDetails.rewardsCount /
                1000000000
            ).toFixed(2)} SMH`
        }
    
        let currentEpochAverageReward = 'Not Available'
        if (networkInfo.price > 0 && rewardDetails.rewardsCount > 0) {
            currentEpochAverageReward = `${new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(
                (rewardDetails.rewardsSum /
                    rewardDetails.rewardsCount /
                    1000000000) *
                    networkInfo.price
            )}`
        }
    
        const predictedRewardsFormatted = `${(rewardDetails.eligibility.predictedRewards / 1000000000).toFixed(2)} SMH`
    
        const rawAverageReward =
            rewardDetails.rewardsSum /
            rewardDetails.rewardsCount /
            1000000000

        let expectedEpochEarnings = 'Not Available'
        if (networkInfo.price > 0 && rewardDetails.rewardsCount > 0) {
            expectedEpochEarnings = `${new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(
                rawAverageReward *
                    rewardDetails.eligibility.count *
                    networkInfo.price
            )}`
        }
    
        if (rewardDetails.eligibility.count < 0) {
            rewardDetails.eligibility.count = 0
        }

        return {
            currentEpochRewardsSumFormatted,
            accountSize,
            currentEpochRewardValue,
            averageRewardSize,
            currentEpochAverageReward,
            predictedRewardsFormatted,
            expectedEpochEarnings,
            sizePerNode
        }
    }

    // Network Calls
    const networkInfo = await fetch(`${API_URL}/network/info`, HEADERS).then(
        (response) => {
            if (!response.ok) {
                return false
            }

            return response.json()
        }
    )

    const responseArray = await Promise.all([
        fetch(`${API_URL}/account/${params.accountId}`, HEADERS),
        fetch(
            `${API_URL}/account/${params.accountId}/rewards/details`, HEADERS
        ),
        fetch(
            `${API_URL}/account/${params.accountId}/atx/${networkInfo.epoch}`, HEADERS
        ),
    ])

    // Handle Calls
    const [accountData, rewardDetails, nodeInfo] = await Promise.all(
        responseArray.map(async (response, index) => {
            if (!response.ok) {
                return false
            }
            if (index === 2) {
                const r = await response.json()
                const total = response.headers.get('total')
                r.total = total
                return {
                    nodes: r,
                    total,
                }
            } else {
                return response.json()
            }
        })
    )

    // If account doesn't exist then just throw error
    if (!accountData) return false

    const balanceFormatted = (accountData.balance / 1000000000).toLocaleString()

    const totalRewardsFormatted = (
        accountData.totalRewards / 1000000000
    ).toLocaleString()

    const accountValue = `${new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(accountData.usdValue / 1000000000)}`

    let rewardDetailsProcessed
    if(rewardDetails) {
        rewardDetailsProcessed = handleRewardDetails(rewardDetails)
    }

    return {
        ...accountData,
        ...rewardDetails,
        ...rewardDetailsProcessed,
        nodeInfo,
        balanceFormatted,
        totalRewardsFormatted,
        accountValue
    }
}

export const getEpochDetails = async (epoch) => {
    const epochDetails = await fetch(
        `${API_URL}/epochs/${epoch}`, HEADERS
    ).then((response) => {
        if (!response.ok) {
            return false
        }

        return response.json()
    })

    return epochDetails
}

export const geteffectiveNumUnitRanking = async (epoch) => {
    let epochDetails

    if (!epoch) {
        epochDetails = await getNetworkInfo()
        epoch = epochDetails.epoch
    } else {
        epochDetails = await getEpochDetails(epoch)
    }

    let data = await fetch(
        `${API_URL}/account/post/epoch/${epoch}?limit=50`, HEADERS
    ).then((response) => {
        if (!response.ok) {
            return false
        }

        return response.json()
    })

    return data.map((node) => {
        const size = `${((node.totalEffectiveNumUnits * 64) / 1024).toFixed(
            2
        )} TiB`
        const share = `${(
            (node.totalEffectiveNumUnits /
                epochDetails.effectiveUnitsCommited) *
            100
        ).toFixed(2)}%`
        return {
            ...node,
            size,
            share,
        }
    })
}

export const getLargestWealth = async () => {
    const data = await fetch(`${API_URL}/account?limit=50`, HEADERS).then(
        (response) => {
            if (!response.ok) {
                return false
            }

            return response.json()
        }
    )

    const networkInfo = await getNetworkInfo()

    return data.map((account) => {
        const share = `${(
            (account.balance / networkInfo.circulatingSupply) *
            100
        ).toFixed(2)}%`
        const totalRewardsFormatted = `${(
            account.totalRewards / 1000000000
        ).toLocaleString()} SMH`
        const balanceFormatted = `${(
            account.balance / 1000000000
        ).toLocaleString()} SMH`
        const accountValueFormatted = `${new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(account.usdValue / 1000000000)}`

        return {
            ...account,
            share,
            totalRewardsFormatted,
            accountValueFormatted,
            balanceFormatted,
        }
    })
}

export const getTransactions = async (currentPage, address) => {
    const response = await fetch(
        `${API_URL}/account/${address}/transactions?sort=desc&limit=20&offset=${
            (currentPage - 1) * 20
        }`, HEADERS
    ).then(async (response) => {
        if (!response.ok) {
            return false
        }
        const total = response.headers.get('total')
        const transactions = await response.json()
        return {
            transactions,
            total,
        }
    })

    const transactions = response.transactions.map((tx) => {
        return {
            ...tx,
            statusFormatted: tx.status === 0 ? 'Successful' : 'Error',
            amountFormatted: `${address === tx.principalAccount ? '-' : '+'}${(
                tx.amount / 1000000000
            ).toFixed(2)} SMH`,
            feeFormatted: `${tx.fee} Smidge`,
            dateFormatted: `${new Date(tx.timestamp * 1000).toLocaleString()}`,
            epoch: Math.floor(tx.layer / 4032),
            type: address === tx.principalAccount ? 'Sent' : 'Received',
        }
    })

    return {
        transactions,
        total: response.total,
    }
}

export const getNodes = async (currentPage, address, epoch) => {
    let epochDetails

    if (!epoch) {
        epochDetails = await getNetworkInfo()
        epoch = epochDetails.epoch
    } else {
        epochDetails = await getEpochDetails(epoch)
    }

    const response = await fetch(
        `${API_URL}/account/${address}/atx/${epoch}?sort=desc&limit=20&offset=${
            (currentPage - 1) * 20
        }`, HEADERS
    ).then(async (response) => {
        if (!response.ok) {
            return false
        }
        const total = response.headers.get('total')
        const nodes = await response.json()
        return {
            nodes,
            total,
        }
    })

    const nodes = response.nodes.map((node) => {
        return {
            ...node,
            sizeFormatted: `${((node.effectiveNumUnits * 64) / 1024).toFixed(
                2
            )} TiB`,
            dateFormatted: `${new Date(node.received).toLocaleString()}`,
        }
    })

    return {
        nodes,
        total: response.total,
    }
}

export const getNodeEligibility = async (nodeId) => {
    const response = await fetch(
        `${API_URL}/nodes/${nodeId}/rewards/eligibility`, HEADERS
    ).then(async (response) => {
        if (!response.ok) {
            return false
        }
        return await response.json()
    })

    return {
        ...response,
        rewardsFormatted: `${(response.predictedRewards / 1000000000).toFixed(
            2
        )} SMH`,
    }
}

export const getNodeData = async (nodeId) => {
    const responseArray = await Promise.all([
        fetch(`${API_URL}/nodes/${nodeId}`, HEADERS),
        fetch(`${API_URL}/nodes/${nodeId}/rewards/eligibility`, HEADERS),
        fetch(`${API_URL}/nodes/${nodeId}/rewards/details`, HEADERS),
    ])

    const [nodeData, nodeEligibility, nodeDetails] = await Promise.all(
        responseArray.map(async (response, index) => {
            if (!response.ok) {
                return false
            }
            if (index === 2) {
                return await response.json()
            } else {
                return response.json()
            }
        })
    )

    return {
        nodeData,
        nodeEligibility,
        nodeDetails,
        expectedRewardsFormatted: `${(
            nodeEligibility.predictedRewards / 1000000000
        ).toFixed(2)} SMH`,
        tickCount: (
            nodeData.Atxs[0].Weight / nodeData.Atxs[0].EffectiveNumUnits
        ).toFixed(2),
        malfeasanceFormatted:
            nodeData.Malfeasance.received === 0 ? 'False' : 'True',
        currentEpochRewardsSumFormatted: `${(
            nodeDetails.currentEpochRewardsSum / 1000000000
        ).toFixed(2)} SMH`,
        weight: nodeData.Atxs[0].Weight,
    }
}

export const getAccountRewards = async (currentPage, address) => {
    const response = await fetch(
        `${API_URL}/account/${address}/rewards?sort=desc&limit=20&offset=${
            (currentPage - 1) * 20
        }`, HEADERS
    ).then(async (response) => {
        if (!response.ok) {
            return false
        }
        const total = response.headers.get('total')
        const rewards = await response.json()
        return {
            rewards,
            total,
        }
    })

    const rewards = response.rewards.map(reward => {
        return {
            ...reward,
            dateFormatted: `${new Date(reward.timestamp*1000).toLocaleString()}`,
            rewardsFormatted: `${(reward.rewards / 1000000000).toFixed(2)} SMH`
        }
    })

    return {
        total: response.total,
        rewards
    }
}

