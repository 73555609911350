import { getNodeData, getNodes } from '../../api/api'
import * as React from 'react'
import Pagination from 'react-bootstrap/Pagination'
import { Container, Card, Row, Col, Badge, Stack } from 'react-bootstrap'
import { faAngleDown, faCopy } from '@fortawesome/free-solid-svg-icons' // Import the desired icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const maxVisiblePages = 5

function NodeCard({ data }) {
    const [showBody, setShowBody] = React.useState(false)
    const [nodeInfo, setNodeInfo] = React.useState({})

    const { node } = data

    const onClickHeader = async () => {
        setShowBody(!showBody)
        if (!showBody) {
            let response = await getNodeData(node.nodeId)
            setNodeInfo(() => response)
        }
    }

    const onCopyData = (data) => {
        navigator.clipboard.writeText(data)
    }

    return (
        <Card bg="light" className="transaction-card-margin">
            <Card.Header style={{ cursor: 'pointer' }}>
                <Container onClick={() => onClickHeader()}>
                    <Row>
                        <Col>
                            <b>{node.nodeId.slice(-8)}</b>
                        </Col>
                        <Col>
                            <b>{node.sizeFormatted}</b>
                        </Col>
                        <Col>
                            <b>{node.effectiveNumUnits} SU</b>
                        </Col>


                    </Row>
                    <Row>
                        <Col md={3}>
                            <span
                                style={{
                                    fontSize: '.8rem',
                                    paddingRight: '10px',
                                }}
                            >
                                {node.dateFormatted}
                            </span>
                        </Col>
                        <Col md={9} style={{ textAlign: 'right' }}>
                            <FontAwesomeIcon size="lg" icon={faAngleDown} />
                        </Col>
                    </Row>
                </Container>
            </Card.Header>
            <Card.Body className={showBody ? null : 'node-card-hide'}>
                {!nodeInfo.nodeEligibility ? null : (
                    <Container>
                        <Row>
                            <Col md={3}>
                                <b>Node ID</b>
                            </Col>
                            <Col className="node-data">
                                {node.nodeId}
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    style={{
                                        cursor: 'pointer',
                                        paddingLeft: '5px',
                                    }}
                                    onClick={() => onCopyData(node.nodeId)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <b>ATX ID</b>
                            </Col>
                            <Col className="node-data">
                                {node.atxId}{' '}
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    style={{
                                        cursor: 'pointer',
                                        paddingLeft: '5px',
                                    }}
                                    onClick={() => onCopyData(node.atxId)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <b>Received</b>
                            </Col>
                            <Col className="node-data">
                                {node.dateFormatted}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <b>SU</b>
                            </Col>
                            <Col className="node-data">
                                {node.effectiveNumUnits}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <b>Size</b>
                            </Col>
                            <Col className="node-data">
                                {node.sizeFormatted}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <b>Current Epoch Rewards</b>
                            </Col>
                            <Col className="node-data">
                                {nodeInfo.currentEpochRewardsSumFormatted}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <b>Expected Rewards</b>
                            </Col>
                            <Col className="node-data">
                                {nodeInfo.expectedRewardsFormatted}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <b>Layers Completed</b>
                            </Col>
                            <Col className="node-data">
                                {nodeInfo.nodeDetails.currentEpochRewardsCount}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <b>Expected Layers</b>
                            </Col>
                            <Col className="node-data">
                                {nodeInfo.nodeEligibility.count}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <b>Tick Count</b>
                            </Col>
                            <Col className="node-data">
                                {nodeInfo.tickCount}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <b>Weight</b>
                            </Col>
                            <Col className="node-data">{nodeInfo.weight}</Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <b>Malfeasance</b>
                            </Col>
                            <Col className="node-data">
                                {nodeInfo.malfeasanceFormatted}
                            </Col>
                        </Row>
                    </Container>
                )}
            </Card.Body>
        </Card>
    )
}

export default function Nodes({ accountInfo }) {
    const [nodes, setNodes] = React.useState([])
    const [currentPage, setCurrentPage] = React.useState(1)
    const [totalPages, setTotalPages] = React.useState(0)

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await getNodes(currentPage, accountInfo.address)
            setTotalPages(Math.ceil(response.total / 20))
            setNodes(response.nodes)
        }
        fetchData()
    }, [currentPage, accountInfo])
    const handlePageChange = (page) => {
        setCurrentPage(page)
    }

    const handleFirstPage = () => {
        setCurrentPage(1)
    }

    const handleLastPage = () => {
        setCurrentPage(totalPages)
    }

    const renderPageNumbers = () => {
        const pageNumbers = []
        const startPage = Math.max(
            1,
            currentPage - Math.floor(maxVisiblePages / 2)
        )
        const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1)

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </Pagination.Item>
            )
        }

        return pageNumbers
    }

    const displayNodes = () => {
        return nodes.map((node) => {
            return <NodeCard key={node.atxId} data={{ node, accountInfo }} />
        })
    }

    return (
        <Container>
            {displayNodes()}
            <Pagination>
                <Pagination.First
                    onClick={handleFirstPage}
                    disabled={currentPage === 1}
                />
                <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                />
                {renderPageNumbers()}
                <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                />
                <Pagination.Last
                    onClick={handleLastPage}
                    disabled={currentPage === totalPages}
                />
            </Pagination>
        </Container>
    )
}
