import * as React from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Container } from 'react-bootstrap'
import LargestAccounts from './rankings/LargestAccounts'
import LargestWealth from './rankings/LargestWealth'

export default function Rankings() {
    return (
        <Container>
            <Tabs
                defaultActiveKey="accountSize"
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="accountSize" title="Largest Accounts by PoST Data">
                    <LargestAccounts />
                </Tab>
                <Tab eventKey="smhRanking" title="Largest Accounts by SMH">
                    <LargestWealth />
                </Tab>
            </Tabs>

        </Container>
    )
}
