import { geteffectiveNumUnitRanking } from '../../api/api'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons' // Import the desired icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

export default function LargestAccounts() {
    const [nodes, setNodes] = React.useState([])

    const getData = async () => {
        const data = await geteffectiveNumUnitRanking()
        setNodes(() => data)
    }

    React.useEffect(() => {
        getData()
    }, [])
    return (
        <Table>
            <thead>
                <tr>
                    <th>Rank</th>
                    <th>Node ID</th>
                    <th>Num Units</th>
                    <th>Size</th>
                    <th>Share</th>
                </tr>
            </thead>
            <tbody>
                {nodes.map((node, index) => {
                    const link = `/accounts/${node.account}`
                    return (
                        <tr key={node.account}>
                            <td>#{index + 1}</td>
                            <td>
                                {node.account.slice(-8)}{' '}
                                <Link to={link}>
                                    <FontAwesomeIcon
                                        icon={faArrowUpRightFromSquare}
                                    />
                                </Link>
                            </td>
                            <td>{node.totalEffectiveNumUnits}</td>
                            <td>{node.size}</td>
                            <td>{node.share}</td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}
