import { useRouteError } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'

export default function TaterError() {
    const error = useRouteError()

    return (
        <Container style={{paddingTop: "20px"}}>
            <Card border='danger' className="text-center">
                <Card.Header>Error</Card.Header>
                <Card.Body>
                    <Card.Title>Looks like there was an error!</Card.Title>
                    <Card.Text>
                        Error: <i>{error.statusText || error.message}</i>
                    </Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted">Press the back button to return from where you came.</Card.Footer>
            </Card>
        </Container>
    )
}
