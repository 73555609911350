import React from 'react'
import { Table, Container, Card, Row, Col } from 'react-bootstrap'

export default function Overview({ accountInfo }) {


    const displayOverview = () => {
        return (
            <Card bg="light">
                <Card.Body>
                    <Container>
                        <Row className='account-details-row-padding'>
                            <h3>Account Details</h3>
                            <Col className="info-card-base">
                                <Card.Title className="info-card-title-sm">
                                    {accountInfo.address}
                                </Card.Title>
                                <Card.Text className="info-card-text">
                                    Account Address
                                </Card.Text>
                            </Col>
                            <Col className="info-card-base">
                                <Card.Title className="info-card-title-sm">
                                    {accountInfo.balanceFormatted} SMH
                                </Card.Title>
                                <Card.Text className="info-card-text">
                                    Account Balance
                                </Card.Text>
                            </Col>
                            <Col className="info-card-base">
                                <Card.Title className="info-card-title-sm">
                                    {accountInfo.accountValue}
                                </Card.Title>
                                <Card.Text className="info-card-text">
                                    Account Value
                                </Card.Text>
                            </Col>
                            <Col className="info-card-base">
                                <Card.Title className="info-card-title-sm">
                                    {accountInfo.numberOfTransactions}
                                </Card.Title>
                                <Card.Text className="info-card-text">
                                    Transactions
                                </Card.Text>
                            </Col>
                        </Row>
                        <Row className='account-details-row-padding'>
                            <h3>Smeshing Details</h3>
                            <Col className="info-card-base">
                                <Card.Title className="info-card-title-sm">
                                    {accountInfo.accountSize ? accountInfo.accountSize : '0 TiB'}
                                </Card.Title>
                                <Card.Text className="info-card-text">
                                    Total PoST Data Size
                                </Card.Text>
                            </Col>
                            <Col className="info-card-base">
                                <Card.Title className="info-card-title-sm">
                                    {accountInfo.nodeInfo.total}
                                </Card.Title>
                                <Card.Text className="info-card-text">
                                    Total Nodes
                                </Card.Text>
                            </Col>
                            <Col className="info-card-base">
                                <Card.Title className="info-card-title-sm">
                                    {accountInfo.sizePerNode ? accountInfo.sizePerNode : 'Not Applicable'}
                                </Card.Title>
                                <Card.Text className="info-card-text">
                                    Average Node Size
                                </Card.Text>
                            </Col>
                        </Row>
                        
                        <Row className='account-details-row-padding'>
                            <h3>
                                Epoch {accountInfo.currentEpoch} Reward Details
                            </h3>
                            { accountInfo.rewardsCount ? (
                            <>
                                <Col className="info-card-base">
                                    <Card.Title className="info-card-title-sm">
                                        {
                                            accountInfo.currentEpochRewardsSumFormatted
                                        }{' '}
                                        ({accountInfo.currentEpochRewardValue})
                                    </Card.Title>
                                    <Card.Text className="info-card-text">
                                        Reward Sum
                                    </Card.Text>
                                </Col>
                                <Col className="info-card-base">
                                    <Card.Title className="info-card-title-sm">
                                        {accountInfo.predictedRewardsFormatted} ({accountInfo.expectedEpochEarnings})
                                    </Card.Title>
                                    <Card.Text className="info-card-text">
                                        Expected Reward Sum
                                    </Card.Text>
                                </Col>
                                <Col className="info-card-base">
                                    <Card.Title className="info-card-title-sm">
                                        {accountInfo.averageRewardSize} (
                                        {accountInfo.currentEpochAverageReward})
                                    </Card.Title>
                                    <Card.Text className="info-card-text">
                                        Average Reward Size
                                    </Card.Text>
                                </Col>
                                <Col className="info-card-base">
                                    <Card.Title className="info-card-title-sm">
                                        {accountInfo.rewardsCount}/
                                        {accountInfo.eligibility.count}
                                    </Card.Title>
                                    <Card.Text className="info-card-text">
                                        Reward Count
                                    </Card.Text>
                                </Col>
                            </>
                           ) : (<h1>Not Eligible This Epoch</h1>)}
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
        )
    }

    return (
        <Container style={{ marginTop: '20px', marginBottom: '20px' }}>
            {displayOverview()}
        </Container>
    )
}
