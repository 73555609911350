import { Card, Col, Container, ProgressBar, Row } from 'react-bootstrap'
import { calculateCycleGap, getPoets } from '../helpers'
import * as React from 'react'
import discordBtn from '../images/discord.png'

export default function Poets() {
    const [poets, setPoets] = React.useState([])

    React.useEffect(() => {
        const updatePoetDates = () => {
            const poets = getPoets()
            const dates = poets.forEach((poet) => {
                poet.dates = calculateCycleGap(poet.phaseShift, poet.duration)
            })
            console.log(poets)
            setPoets(poets)
        }
        updatePoetDates()
        
        const intervalId = setInterval(updatePoetDates, 1000)
        return () => clearInterval(intervalId)
    }, [])

    const displayCycleGapTicker = (dates) => {
        return (
            <>
                <Card bg="light" className="text-center">
                    <Card.Body>
                        <Container>
                            <Row>
                                <Col md={2}>{dates.nextGapStart}</Col>
                                <Col>
                                    <ProgressBar
                                        variant="spacemesh"
                                        now={dates.cycleGapProgress}
                                    />
                                </Col>
                                <Col md={2}>{dates.nextGapEnd}</Col>
                            </Row>
                            <Row>
                                <Col className="info-card-base">
                                    <Card.Title className="info-card-title">
                                        {dates.timeUntilCycleGapEnds}
                                    </Card.Title>
                                    <Card.Text className="info-card-text">
                                        Cycle Gap Ends in
                                    </Card.Text>
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </>
        )
    }

    const displayPoetCycleTicker = (dates) => {
        return (
            <>
                <Card bg="light" className="text-center">
                    <Card.Body>
                        <Container>
                            <Row>
                                <Col>{dates.poetCycleStart}</Col>
                                <Col md={8}>
                                    <ProgressBar
                                        variant="spacemesh"
                                        now={dates.cycleProgress}
                                    />
                                </Col>
                                <Col>{dates.poetCycleEnd}</Col>
                            </Row>
                            <Row>
                                <Col className="info-card-base">
                                    <Card.Title className="info-card-title">
                                        {dates.timeUntilCycleEnds}
                                    </Card.Title>
                                    <Card.Text className="info-card-text">
                                        PoET Cycle Ends in
                                    </Card.Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <b>Cycle Gap Start:</b> {dates.nextGapStart} | <b>Cycle Gap End:</b> {dates.nextGapEnd}
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </>
        )
    }

    const displayPoETs = () => {
        return poets.map((poet) => {
            return (
                <Card
                    style={{ marginBottom: '10px' }}
                    bg="light"
                    key={poet.poetName}
                >
                    <Card.Body>
                        <Container>
                            <Row className="text-left">
                                <Col>
                                    <a target="_blank" href={poet.discordLink}>
                                        <img
                                            style={{ height: '20px' }}
                                            src={discordBtn}
                                        />
                                    </a>
                                    <hr />
                                </Col>
                            </Row>
                            <Row className="text-center">
                                <Col className="info-card-base">
                                    <Card.Title className="info-card-title">
                                        {poet.poetName}
                                    </Card.Title>
                                    <Card.Text className="info-card-text">
                                        PoET
                                    </Card.Text>
                                </Col>
                                <Col className="info-card-base">
                                    <Card.Title className="info-card-title">
                                        {poet.duration} Hours
                                    </Card.Title>
                                    <Card.Text className="info-card-text">
                                        Cycle Gap
                                    </Card.Text>
                                </Col>
                                <Col className="info-card-base">
                                    <Card.Title className="info-card-title">
                                        {poet.phaseShift} Hours
                                    </Card.Title>
                                    <Card.Text className="info-card-text">
                                        Phase Shift
                                    </Card.Text>
                                </Col>
                                <Col className="info-card-base">
                                    <Card.Title className="info-card-title">
                                        {poet.endpoints.length}
                                    </Card.Title>
                                    <Card.Text className="info-card-text">
                                        Servers
                                    </Card.Text>
                                </Col>
                            </Row>
                            <Row>
                                {poet.dates.cycleGapStatus === 'In Progress'
                                    ? displayCycleGapTicker(poet.dates)
                                    : displayPoetCycleTicker(poet.dates)}
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            )
        })
    }

    return (
        <Container style={{ marginTop: '20px', marginBottom: '20px' }}>
            {displayPoETs()}
        </Container>
    )
}
