import { Alert, Container } from "react-bootstrap"
import TaterNav from "./components/TaterNav"
import { Outlet } from "react-router-dom"
import TaterLowerNav from "./components/TaterLowerNav"

function App() {
    return (
	<Container >
		<TaterNav />
		<Outlet />
		<div style={{padding: "60px"}}></div>
		<TaterLowerNav />
	</Container>
	)
}

export default App
